import { UnauthorizedError } from "utils/request";

const authMiddleware = (store) => (next) => (action) => {
  // Check if the action contains an error
  if (action.err instanceof UnauthorizedError) {
    // Dispatch the logout action
    store.dispatch({ type: "auth/logout" });
  }
  return next(action);
};

export default authMiddleware;

