import { request } from "utils/request"
import { asyncAction, addToList, removeFromList } from "./common"
const initialState = {
  isLoading: false,
  isFetching: false,
  status: "initial",
  availableRepositories: [],
  selectedChatbot: {
    name: "",
    id: ""
  },
  template: "",
  chatbots: [],
  config: {
    generalPrefix: "",
    conversationPrompt: "",
    relevantDocumentation: "",
    descriptionPrompt: "",
    topDescriptionPrompt: "",
    filePathPrompt: "",
  },
  conversations: [],
  activeConversation: "",
  conversation: [],
}


const newChatState = (state, defaultChatbotId) => {
  const defaultChatbot = state.chatbots.find((chatbot) => chatbot.id === defaultChatbotId)
  return {
    ...initialState,
    chatbots: state.chatbots,
    availableRepositories: state.availableRepositories,
    selectedChatbot: defaultChatbot,
    conversations: state.conversations,
  }
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "librarian/fetchRepositories":
      return { ...state, availableRepositories: action.res }
    case "librarian/fetchChatbots/BEGIN":
      return { ...state, isLoading: true }
    case "librarian/fetchChatbots":
      return { ...state, isLoading: false, chatbots: action.res }
    case "librarian/fetchAdminChatbots/BEGIN":
      return { ...state, isLoading: true }
    case "librarian/fetchAdminChatbots":
      return { ...state, isLoading: false, chatbots: action.res }
    case "librarian/getAnswer/BEGIN":
      return { ...state, isFetching: true, status: "begun" }
    case "librarian/getAnswer/ERROR":
      return { ...state, isFetching: false, status: "failed" }
    case "librarian/getAnswer":
      const { answer, chatbotId, conversationId } = action.res;

      if (conversationId !== state.activeConversation) {
        return { ...state, isFetching: false, status: "finished" };
      }

      return {
        ...state,
        conversation: [...state.conversation, answer],
        conversations: state.conversations.map((conversation) => {
          if (conversation.id === conversationId) {
            return {
              ...conversation,
              chatbotId: chatbotId,
            };
          }
          return conversation;
        }),
        isFetching: false,
        status: "finished",
      }
    case "librarian/getSpeechToTextQuestion":
      return {
        ...state,
        isFetching: false,
        status: "finished",
      };
    case "librarian/addToConversation":
      return { ...state, conversation: [...state.conversation, action.message] }
    case "librarian/setChatbot":
      return { ...state, selectedChatbot: action.selectedChatbot }
    case "librarian/setConversation":
      return { ...state, activeConversation: action.conversation, isFetching: false, status: "finished"}
    case "librarian/setTemplate":
      return { ...state, template: action.template }
    case "librarian/refreshChat":
      return newChatState(state, action.defaultChatbot)
    case "librarian/updateHelpRepository/BEGIN":
      return { ...state, isLoading: true }
    case "librarian/updateHelpRepository":
      return { ...state, isLoading: false, ...action.res }
    case "librarian/updateHelpRepository/ERROR":
      return { ...state, isLoading: false, error: action.error }
    case "librarian/createTeamRepository":
      return {
        ...state,
        availableRepositories: addToList(state.availableRepositories, {
          ...action.res.repository,
          uniqueForTeam: true,
          processingErrors: [],
        }),
      }
    case "librarian/editRepository":
        return {
          ...state,
          availableRepositories: state.availableRepositories.map((item) => (item.id === action.args[0] ? {
            ...item,
            name: action.res.repository.name
          } : item))
        }
    case "repositories/remove":
      return {
        ...state,
        availableRepositories: removeFromList(state.availableRepositories, action.res)
      }
    case "repositories/startProcessing": {
      const repositoryId = action.args[0];

      const updatedRepositories = state.availableRepositories.map((repository) => {
        if (repository.id === repositoryId) {
          return {
            ...repository,
            isProcessing: true,
          };
        }
        return repository;
      });

      return {
        ...state,
        availableRepositories: updatedRepositories,
      };
    }

    case "librarian/deleteChatbot":
      return {
        ...state,
        chatbots: removeFromList(state.chatbots, action.res)
      }
    case "librarian/createChatbot":
      return {
        ...state,
        chatbots: addToList(state.chatbots, { ...action.res.chatbot, uniqueForTeam: true })
      }
    case "librarian/createAdminChatbot":
      return {
        ...state,
        chatbots: addToList(state.chatbots, { ...action.res.chatbot, uniqueForTeam: false })
      }
    case "librarian/createGlobalChatbot":
      return {
        ...state,
        chatbots: addToList(state.chatbots, { ...action.res.chatbot, uniqueForTeam: false })
      }
    case "librarian/editChatbot":
      return {
        ...state,
        chatbots: state.chatbots.map((item) => (item.id === action.args[0] ? {
          ...item,
          name: action.res.chatbot.name,
          systemPrompt: action.res.chatbot.systemPrompt,
          repositoryIds: action.res.chatbot.repositoryIds
        } : item))
      }
    case "librarian/editAdminChatbot":
      return {
        ...state,
        chatbots: state.chatbots.map((item) => (item.id === action.args[0] ? {
          ...item,
          name: action.res.chatbot.name,
          systemPrompt: action.res.chatbot.systemPrompt,
          repositoryIds: action.res.chatbot.repositoryIds
        } : item))
      }
    case "librarian/setGeneralPrefix":
      return {
        ...state,
        config: {
          ...state.config,
          generalPrefix: action.res.generalPrefix
        }
      }
    case "librarian/setRelevantDocumentation":
      return {
        ...state,
        config: {
          ...state.config,
          relevantDocumentation: action.res.relevantDocumentationString
        }
      }
    case "librarian/setConversationPrompt":
      return {
        ...state,
        config: {
          ...state.config,
          conversationPrompt: action.res.conversationPrompt
        }
      }
    case "librarian/setCurrentQuestion":
      return {
        ...state,
        config: {
          ...state.config,
          currentQuestion: action.res.currentQuestionPrompt
        }
      }
    case "librarian/setDescriptionPrompt":
      return {
        ...state,
        config: {
          ...state.config,
          descriptionPrompt: action.res.descriptionPrompt
        }
      }
    case "librarian/setTopDescriptionPrompt":
      return {
        ...state,
        config: {
          ...state.config,
          topDescriptionPrompt: action.res.topDescriptionPrompt
        }
      }
    case "librarian/setFilePathPrompt":
      return {
        ...state,
        config: {
          ...state.config,
          filePathPrompt: action.res.filePathPrompt
        }
      }
    case "librarian/setTitlePrompt":
      return {
        ...state,
        config: {
          ...state.config,
          titlePrompt: action.res.titlePrompt
        }
      }
    case "librarian/fetchConfig":
      return {
        ...state,
        config: {
          generalPrefix: action.res.generalPrefix,
          conversationPrompt: action.res.conversationPrompt,
          currentQuestion: action.res.currentQuestionPrompt,
          relevantDocumentation: action.res.relevantDocumentationString,
          titlePrompt: action.res.titlePrompt,
          descriptionPrompt: action.res.descriptionPrompt,
          topDescriptionPrompt: action.res.topDescriptionPrompt,
          filePathPrompt: action.res.filePathPrompt,
        }
      }
    case "librarian/fetchConversations":
      return {
        ...state,
        conversations: sortConversations(action.res),
      };
    case "librarian/fetchConversation": {
      const messages = action.res.response.messages
      return {
        ...state,
        conversation: messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
        status: "finished",
      };
    }
    case "librarian/editConversation":
      const updatedConversations = state.conversations.map((item) =>
        item.id === action.args[0] ? action.res.conversation : item
      );
      return {
        ...state,
        conversations: sortConversations(updatedConversations),
      };
    case "librarian/deleteConversation":
      return {
        ...state,
        conversations: removeFromList(state.conversations, action.res)
      }
    case "auth/login":
    case "auth/verifyMfaToken":
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetchRepositories = asyncAction("librarian/fetchRepositories", (dispatch) => {
  return request("GET", `/librarian/repository/user-repositories`).then((res) => {
    return res.items
  })
})

export function addToConversation(message) {
  return (dispatch) => {
    dispatch({ type: "librarian/addToConversation", message })
  }
}

export const getAnswer = asyncAction("librarian/getAnswer", (dispatch, message, chatbotId, conversationId) => {
  return request("POST", `/librarian/chat/chatbot/${chatbotId}`, { input: { message: message, conversationId: conversationId } }).then((res) => {
    if (!window.store.getState().librarian.conversations.some((conversation) => conversation.id === res.conversationId)) {
      dispatch(fetchConversations()).then(() => {
        dispatch(setConversation(res.conversationId))
      })
    }
    return { answer: res.answer, chatbotId: chatbotId, conversationId: res.conversationId };
  })
})

export const getSpeechToTextQuestion = asyncAction("librarian/getSpeechToTextQuestion", (dispatch, audioFile) => {
  const formData = new FormData();
  formData.append('audioFile.wav', audioFile);
    return request("POST", `/librarian/speech-to-text`, formData, false).then((res) => {
      return { ...res}
    })
});

export const setChatbot = (selectedChatbot => {
  return (dispatch) => {
    dispatch({ type: "librarian/setChatbot", selectedChatbot })
  }
})

export const setConversation = (conversation) => {
  return (dispatch) => {
    dispatch(fetchConversation(conversation))
    dispatch({ type: "librarian/setConversation", conversation })
  }
}

export const setTemplate = (template) => {
  return (dispatch) => {
    dispatch({ type: "librarian/setTemplate", template })
  }
}

export function refreshChat() {
  return (dispatch, getState) => {
    const defaultChatbot = getState().auth.defaultChatbot
    dispatch({ type: "librarian/refreshChat", defaultChatbot })
  }
}

export const updateHelpRepository = asyncAction(
  "librarian/updateHelpRepository",
  (dispatch, id) => {
    // Perform the POST request to update the help repository
    return request("POST", `/librarian/extract/pt-help/${id}`)
      .then((res) => {
        // Return the response to be dispatched and handled by the reducer
        return res;
      })
      .catch((error) => {
        console.error('Failed to update help repository:', error);
        // Re-throw the error to allow the asyncAction handler to dispatch an ERROR action
        throw error;
      });
  }
)

export const createTeamRepository = asyncAction(
  "librarian/createTeamRepository",
  (dispatch, input) => {
    return request("POST", `/librarian/repository/create/planner`, { input }).then((res) => res)
  }
)

export const editRepository = asyncAction(
  "librarian/editRepository",
  (dispatch, id, patch) => {
    return request("PATCH", `/librarian/repository/update/planner/${id}`, { patch }).then((res) => res)
  }
)

export const fetchChatbots = asyncAction("librarian/fetchChatbots", (dispatch) => {
  return request("GET", `/librarian/chatbot/user-chatbots`).then((res) => {
    return res.items
  })
})

export const fetchAdminChatbots = asyncAction("librarian/fetchAdminChatbots", (dispatch) => {
  return request("GET", `/librarian/chatbot/admin-chatbots`).then((res) => {
    return res.items
  })
})

export const createChatbot = asyncAction(
  "librarian/createChatbot",
  (dispatch, input) => {
    return request("POST", `/librarian/chatbot/create/planner`, { input }).then((res) => res)
  }
)

export const createAdminChatbot = asyncAction(
  "librarian/createAdminChatbot",
  (dispatch, input) => {
    return request("POST", `/librarian/chatbot/create`, { input }).then((res) => res)
  }
)

export const createGlobalChatbot = asyncAction(
  "librarian/createGlobalChatbot",
  (dispatch, input) => {
    return request("POST", `/librarian/chatbot/create-global`, { input }).then((res) => res)
  }
)

export const editChatbot = asyncAction(
  "librarian/editChatbot",
  (dispatch, id, patch) => {
    return request("PATCH", `/librarian/chatbot/planner/${id}`, { patch }).then((res) => res)
  }
)

export const editAdminChatbot = asyncAction(
  "librarian/editAdminChatbot",
  (dispatch, id, patch) => {
    return request("PATCH", `/librarian/chatbot/${id}`, { patch }).then((res) => res)
  }
)

export const deleteChatbot = asyncAction(
  "librarian/deleteChatbot",
  (dispatch, id) => {
    return request("DELETE", `/librarian/chatbot/${id}`).then(() => ({ id }))
  }
)

export const setGeneralPrefix = asyncAction("librarian/setGeneralPrefix", (dispatch, prefix) => {
  return request("PATCH", `/customer-admin/set-general-prefix`, prefix).then((res) => res)
})

export const setRelevantDocumentation = asyncAction("librarian/setRelevantDocumentation", (dispatch, relevantDocumentation) => {
  return request("PATCH", `/customer-admin/set-relevant-documentation-string`, relevantDocumentation).then((res) => res)
})

export const setConversationPrompt = asyncAction("librarian/setConversationPrompt", (dispatch, conversationPrompt) => {
  return request("PATCH", `/customer-admin/set-conversation-prompt`, conversationPrompt).then((res) => res)
})

export const setCurrentQuestion = asyncAction("librarian/setCurrentQuestion", (dispatch, currentQuestion) => {
  return request("PATCH", `/customer-admin/set-current-question-prompt`, currentQuestion).then((res) => res)
})

export const setTitlePrompt = asyncAction("librarian/setTitlePrompt", (dispatch, titlePrompt) => {
  return request("PATCH", `/customer-admin/set-title-prompt`, titlePrompt).then((res) => res)
})

export const setDescriptionPrompt = asyncAction("librarian/setDescriptionPrompt", (dispatch, descriptionPrompt) => {
  return request("PATCH", `/customer-admin/set-description-prompt`, descriptionPrompt).then((res) => res)
})

export const setTopDescriptionPrompt = asyncAction("librarian/setTopDescriptionPrompt", (dispatch, topDescriptionPrompt) => {
  return request("PATCH", `/customer-admin/set-top-description-prompt`, topDescriptionPrompt).then((res) => res)
})

export const setFilePathPrompt = asyncAction("librarian/setFilePathPrompt", (dispatch, filePathPrompt) => {
  return request("PATCH", `/customer-admin/set-file-path-prompt`, filePathPrompt).then((res) => res)
})

export const fetchConfig = asyncAction("librarian/fetchConfig", (dispatch) => {
  return request("GET", `/customer-admin/get-config-strings`).then((res) => res)
})

export const fetchConversations = asyncAction("librarian/fetchConversations", (dispatch) => {
  return request("GET", `/conversation/get-all`).then((res) => res.items)
})

export const fetchConversation = asyncAction("librarian/fetchConversation", (dispatch, id) => {
  return request("GET", `/conversation/${id}`).then((res) => res)
})

export const editConversation = asyncAction("librarian/editConversation", (dispatch, id, patch) => {
  return request("PATCH", `/conversation/${id}`, { patch }).then((res) => res)
})

export const deleteConversation = asyncAction("librarian/deleteConversation", (dispatch, id) => {
  return request("DELETE", `/conversation/${id}`).then(() => {
    if (id === window.store.getState().librarian.activeConversation) {
      dispatch(refreshChat())
    }
    return { id }
  })
})

export const sortConversations = (conversations) => {
  return [...conversations].sort((a, b) => {
    // Prioritize pinned conversations
    if (a.isPinned && !b.isPinned) return -1;
    if (!a.isPinned && b.isPinned) return 1;

    // If both are pinned or unpinned, sort by updatedAt descending
    return new Date(b.updatedAt) - new Date(a.updatedAt);
  });
};
