import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FiTrash2, FiSettings, FiPlus } from "react-icons/fi"

import "./index.scss"

import { chooseOptimalColor } from "utils/scripts/schedule"
import { ListGroup } from "react-bootstrap"
import { Popup, ListItem, ConfirmDialog } from "components"
import { CompetenceGroupConfig } from "components/Config"
import { competenceGroups } from "state_management"

export default function CompetenceGroupTab() {
  const [addConfig, setAddConfig] = useState(false)
  const [activeEdit, setActiveEdit] = useState(null)
  const [activeDelete, setActiveDelete] = useState(null)
  const [deleteBreaking, setDeleteBreaking] = useState(null)

  const projects = useSelector((state) => state.projects)
  const analysisDefinitions = useSelector((state) => state.analysisDefinitions)
  const groups = Object.values(useSelector((state) => state.competenceGroups)).sort((a, b) => {
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
    return 0
  })
  const dispatch = useDispatch()

  function nameChecker(name, groupName = "") {
    if (groups.some((e) => e.name === name && e.name !== groupName)) {
      return "A competence group with that name already exists"
    }
    return ""
  }

  function checkCompetenceGroupSafeDelete(competenceGroupId) {
    let foundAnalysesAndProjects = [];
    let addedIds = new Set();

    Object.values(projects).forEach((project) => {
      Object.values(project.actions).forEach((action) => {
        if (action.allowedGroupIds.includes(competenceGroupId)) {
          // Check if the project's ID has already been added
          if (!addedIds.has(project.id)) {
            foundAnalysesAndProjects.push(project);
            addedIds.add(project.id);
          }
        }
      });
    });

    Object.values(analysisDefinitions).forEach((analysis) => {
      Object.values(analysis.taskDefinitions).forEach((task) => {
        if (task.allowedGroupIds.includes(competenceGroupId)) {
          // Check if the analysis's ID has already been added
          if (!addedIds.has(analysis.id)) {
            foundAnalysesAndProjects.push(analysis);
            addedIds.add(analysis.id);
          }
        }
      });
    });

    // Can be added to also include planned analysis tasks
    // Object.values(analysisTasks).forEach((task) => {
    //   if (task.allowedGroupIds.includes(competenceGroupId)) {
    //     // Check if the analysis's ID has already been added
    //     if (!addedIds.has(task.analysisId)) {
    //       foundAnalysesAndProjects.push(task);
    //       addedIds.add(task.analysisId);
    //     }
    //   }
    // })

    return foundAnalysesAndProjects;
  }


  return (
    <div className="TeamTab-main-container">
      {activeDelete && (
        <ConfirmDialog
          onCancel={() => setActiveDelete(null)}
          onConfirm={() =>
            dispatch(competenceGroups.remove(activeDelete.id)).then(
              () => setActiveDelete(null),
              () => setActiveDelete(null)
            )
          }
        >
          <h4>Confirm deletion of competence group:</h4>
          <h5>{activeDelete.name}</h5>
        </ConfirmDialog>
      )}
      {deleteBreaking && (
        <Popup
          onCancel={() => setDeleteBreaking(null)}
          title="Deleting this will leave tasks in the following analyses without a Competence Group. Ensure replacement before
        removal."
        >
          <ListGroup>
            {deleteBreaking.map((analysis) => (
              <ListGroup.Item
                key={analysis.id}
                style={{ backgroundColor: analysis.color, color: chooseOptimalColor(analysis.color) }}
              >
                {analysis.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Popup>
      )}
      {activeEdit && (
        <Popup nonScrollable onCancel={() => setActiveEdit(null)}>
          <CompetenceGroupConfig group={activeEdit} onClose={() => setActiveEdit(null)} nameCheck={nameChecker} />
        </Popup>
      )}
      {addConfig && (
        <Popup nonScrollable onCancel={() => setAddConfig(false)}>
          <CompetenceGroupConfig onClose={() => setAddConfig(false)} nameCheck={nameChecker} />
        </Popup>
      )}
      <div className="CompetenceGroupTab-list">
        {groups.map((group) => (
          <ListItem text={group.name} key={group.id} maxWidth={400}>
            <FiTrash2
              onClick={() => {
                const foundAnalyses = checkCompetenceGroupSafeDelete(group.id);
                if (foundAnalyses?.length > 0) {
                  setDeleteBreaking(foundAnalyses)
                } else {
                  setActiveDelete(group)
                }
              }}
              title="Delete Competence Group"
            />
            <FiSettings onClick={() => setActiveEdit(group)} title="Edit Competence Group" />
          </ListItem>
        ))}
        <div className="CompetenceGroupTab-add-group">
          <FiPlus title="Add Competence Group" onClick={() => setAddConfig(true)} />
        </div>
      </div>
    </div>
  )
}
