import React from "react"
import "./index.scss"

export default function ListItem({ text, children, maxWidth }) {
  const textStyle = maxWidth
    ? {
      maxWidth: `${maxWidth}px`, // or any unit you prefer
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
    : {};

  return (
    <div className="list-item">
      <p style={textStyle} title={text}>
        {text}
      </p>
      {children}
    </div>
  );
}
