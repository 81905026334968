import { Dropdown, InputGroup } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { FiArrowLeft, FiLogOut, FiHome, FiSettings, FiPlus, FiXSquare, FiCheckSquare, FiSquare } from "react-icons/fi"
import { TbSquareLetterG, TbSquareLetterT, TbSquareLetterC } from "react-icons/tb"
import { HiOutlinePencilAlt } from "react-icons/hi";

import { auth, teamStatus, librarian } from "state_management"
import isOnMobile from "utils/scripts/isOnMobile"
import { ConfigContainer, Popup } from "components"
import { RepositoryConfig, ChatbotConfig, MembersConfig } from "components/Config"
import { Container, Select, Title } from "components/Form"
import { languages } from "utils/scripts/languages"
import { Button, Spinner } from "react-bootstrap"
import { RepositoryComponent } from "./RepositoryComponent"


export const Toolbar = ({ toggleLibrarianHistory, isReferencesOpen }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isInPlanningMode = useSelector((state) => state.teamStatus.isInPlanningMode)
  const isCurrentPlanner = useSelector((state) => state.teamStatus.isCurrentPlanner)
  const isOnlyLibrarian = useSelector((state) => state.auth.isOnlyLibrarian)
  const isMember = useSelector((state) => state.auth.isMember)
  const selectedChatbot = useSelector((state) => state.librarian.selectedChatbot)
  const repositories = useSelector((state) => state.librarian.availableRepositories)
  const language = useSelector((state) => state.auth.language)
  const memberId = useSelector((state) => state.auth.memberId)
  const chatbots = useSelector((state) => state.librarian.chatbots)
  const directToMemberPlan = useSelector((state) => state.teamStatus.directToMemberPlan)
  const defaultChatbotRedux = useSelector((state) => state.auth.defaultChatbot)
  const onlyLibrarian = useSelector((state) => state.teamStatus.onlyLibrarian)

  const [initialLanguage, setInitialLanguage] = useState(null);
  const [defaultChatbot, setDefaultChatbot] = useState(defaultChatbotRedux)
  const [showRepositorySettings, setShowRepositorySettings] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [showRepositoryConfig, setShowRepositoryConfig] = useState(false)
  const [showRepositoryUpload, setShowRepositoryUpload] = useState(false)
  const [showChatbotSettings, setShowChatbotSettings] = useState(false)
  const [repository, setRepository] = useState(null)
  const [chatbot, setChatbot] = useState(null)
  const [showChatbots, setShowChatbots] = useState(false)
  const [showTeam, setShowTeam] = useState(false)

  useEffect(() => {
    if (language && initialLanguage === null) {
      setInitialLanguage(language);
    }
  }, [language, initialLanguage]);

  const handleLanguageChange = (item) => {
    dispatch(auth.setLanguage(item[0]))
  }

  return (
    <div className="chat-messages-toolbar">
      {!isMember && showTeam && (
        <Popup title={"Edit team"} onCancel={() => {
          setShowTeam(false)
        }
        }>
          <Button className="go-back-button" variant="secondary" onClick={() => {
            setShowTeam(false)
            setShowSettings(true)
          }}>
            <FiArrowLeft />
          </Button>
          <MembersConfig />
        </Popup>
      )}
      {!isMember && showChatbots && (
        <Popup title={"Chatbots"} onCancel={() => {
          setShowChatbots(false)
        }}>
          <Button className="go-back-button" variant="secondary" onClick={() => {
            setShowChatbots(false)
            setShowSettings(true)
          }}>
            <FiArrowLeft />
          </Button>
          <div className="librarian-team-boxes-scroll">
            {chatbots.map((chatbot) => chatbot.uniqueForTeam && (
              <div
                key={chatbot.id}
                className="librarian-team-box librarian-chatbot-box-color librarian-team-box-hovereffect"
                title={`Edit ${chatbot.name}`}
                onClick={() => {
                  setChatbot(chatbot)
                  setShowChatbotSettings(true)
                  setShowChatbots(false)
                }}
              >
                <div className="librarian-repository-box-inner">
                  <p>{chatbot.name}</p>
                </div>
              </div>
            ))}
            <div className="librarian-team-box">
              <div className="librarian-team-fiPlus-container">
                <FiPlus
                  className="librarian-team-fiPlus"
                  title="Add chatbot"
                  onClick={() => {
                    setShowChatbots(false)
                    setChatbot(null)
                    setShowChatbotSettings(true)
                  }}
                  color="black"
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      {!isMember && showChatbotSettings && (
        <Popup onCancel={() => setShowChatbotSettings(false)}>
          <ChatbotConfig repositories={repositories} chatbotData={chatbot}
            onCancel={() => {
              setShowChatbotSettings(false)
              setShowChatbots(true)
            }}
          />
        </Popup>
      )}
      {!isMember && showRepositoryUpload && (
        <Popup onCancel={() => setShowRepositoryUpload(false)}>
          <RepositoryComponent repository={repository} onClose={() => {
            setShowRepositorySettings(true)
            setShowRepositoryUpload(false)
          }}
          />
        </Popup>
      )}
      {!isMember && showRepositoryConfig && (
        <Popup nonScrollable onCancel={() => setShowRepositoryConfig(false)}>
          <RepositoryConfig repositoryData={repository} onClose={() => {
          setShowRepositoryConfig(false)
          setShowRepositorySettings(true)
          }}
          />
        </Popup>
      )}
      {!isMember && showRepositorySettings && (
        <Popup
          title="Repositories"
          onCancel={() => {
            setShowRepositorySettings(false)
          }}
        >
          <Button className="go-back-button" variant="secondary" onClick={() => {
            setShowRepositorySettings(false)
            setShowSettings(true)
          }}>
            <FiArrowLeft />
          </Button>
          <div className="librarian-team-boxes-scroll">
            {repositories.map((repository) => repository.uniqueForTeam && (
              <div
                key={repository.id}
                className="librarian-team-box librarian-repository-box-color librarian-team-box-hovereffect"
                title={`Upload to ${repository.name}`}
                onClick={() => {
                  setRepository(repository)
                  setShowRepositoryUpload(true)
                  setShowRepositorySettings(false)
                }}
              >
                <div className="librarian-repository-box-inner">
                  <p>{repository.name}</p>
                </div>
                <div className="repository-processing-icon">
                  <div className="repository-processing-icon">
                    {repository.processingErrors.length > 0 ? (
                      <FiXSquare title="There were errors processing this repository" />
                    ) : repository.isProcessing ? (
                        <div className="repository-spinner">
                          <Spinner
                            animation="border"
                            size="sm"
                            title="Currently processing this repository"
                          />
                        </div>
                      ) : repository.unprocessedFiles ? (
                          <FiSquare title="This repository has unprocessed files" />
                        ) : repository.isProcessed ? (
                            <FiCheckSquare title="This repository is processed" />
                          ) : null}
                  </div>
                </div>
                <FiSettings
                  className="librarian-team-fiSettings admin-icons-hovereffect"
                  title="Settings"
                  onClick={(e) => {
                    e.stopPropagation()
                    setRepository(repository)
                    setShowRepositoryConfig(true)
                    setShowRepositorySettings(false)
                  }}
                />
              </div>
            ))}
            <div className="librarian-team-box">
              <div className="librarian-team-fiPlus-container">
                <FiPlus
                  className="librarian-team-fiPlus"
                  title="Add repository"
                  onClick={() => {
                    setShowRepositorySettings(false)
                    setShowRepositoryConfig(true)
                    setRepository(null)
                  }}
                  color="black"
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      {showSettings && (
        <Popup
          onCancel={() => {
            setShowSettings(false)
            if (initialLanguage !== null) {
              dispatch(auth.setLanguage(initialLanguage))
            }
          }}
        >
          <ConfigContainer
            title="Settings for QC Librarian"
            submitHandler={() => {
              setShowSettings(false)
              if (language !== initialLanguage) {
                dispatch(auth.setLanguage(language))
              }
              if (!defaultChatbotRedux) {
                if (defaultChatbot) {
                  dispatch(auth.setDefaultChatbot(defaultChatbot[0]))
                }
              } else if (defaultChatbot !== defaultChatbotRedux) {
                dispatch(auth.setDefaultChatbot(defaultChatbot[0]))
              }
            }}
            cancelHandler={() => {
              setShowSettings(false)
              dispatch(auth.setLanguage(initialLanguage))
            }}
          >
            <Container>
              <InputGroup>
                <Title>Speech-to-text language</Title>
                <Select
                  isMulti={false}
                  required={true}
                  disabled={false}
                  collection={languages}
                  value={language}
                  onChange={handleLanguageChange}
                  menuPosition="fixed"
                />
              </InputGroup>
            </Container>
            <Container>
              <InputGroup>
              <Title>Default chatbot</Title>
              <Select
                isMulti={false}
                required={false}
                disabled={false}
                defaultValue={{ label: "Select default chatbot", value: 0 }}
                placeholder={"Select chatbot"}
                collection={chatbots}
                value={defaultChatbot}
                onChange={setDefaultChatbot}
                menuPosition="fixed"
                includeNone={true}
              />
              </InputGroup>
            </Container>
            <Container>
              {!isMember && (
                <Button variant="primary" style={{ zIndex:0 }} onClick={() => {
                  setShowSettings(false)
                  setShowChatbots(true)
                }}>
                  Create/edit chatbots
                </Button>
              )}
            </Container>
            <Container>
              {!isMember && (
                <Button variant="primary" style={{ zIndex:0 }} onClick={() => {
                  setShowSettings(false)
                  setShowRepositorySettings(true)
                }}>
                  Create/edit repositories
                </Button>
              )}
            </Container>
            {!isMember && onlyLibrarian && (
            <Container>
                <Button variant="primary" style={{ zIndex:0 }} onClick={() => {
                  setShowSettings(false)
                  setShowTeam(true)
                }}>
                  Edit team
                </Button>
            </Container>
            )}
          </ConfigContainer>
        </Popup>
      )}
      {/* <div className="chat-messages-toolbar-dropdown">
                <Dropdown
                    onSelect={(eventKey) => {
                        dispatch(librarian.setTemplate(eventKey))
                    }}
                >
                    <Dropdown.Toggle variant="primary" disabled={conversationState.isFetching}>{template === "" ? "Select template" : template}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="Declaration of Independence">Declaration of Independence</Dropdown.Item>
                        <Dropdown.Item eventKey="Area 51 documents">Area 51 documents</Dropdown.Item>
                        <Dropdown.Item eventKey="USSR Nuclear Codes">USSR Nuclear Codes</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div> */}
      {isOnMobile && (
        <Button variant="primary" className="burger-menu" onClick={toggleLibrarianHistory}>
            <HiOutlinePencilAlt title={"History"} />
        </Button>
      )}
      <div className={`chat-messages-toolbar-dropdown${isOnMobile ? "-mobile" : ""}`}>
        <Dropdown
          onSelect={(eventKey) => {
            if (eventKey === "none") {
              dispatch(librarian.setChatbot({ id: "", name: "" }))
            } else {
              dispatch(librarian.setChatbot(chatbots.find((chatbot) => chatbot.id === eventKey)))
            }
          }}
        >
          <Dropdown.Toggle variant="primary">
            Select chatbot
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* "None" Option */}
            <Dropdown.Item eventKey="none" active={selectedChatbot === null}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>None</span>
              </div>
            </Dropdown.Item>

            <Dropdown.Divider /> {/* Optional: Add a divider for better UI separation */}

            {chatbots.length > 0 &&
              chatbots.map((chatbot) => (
                <Dropdown.Item key={chatbot.id} eventKey={chatbot.id}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <span
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '250px', // Adjust this value as needed
                        marginRight: '10px', // Space between name and icon
                      }}
                      title={chatbot.name} // Optional: Show full name on hover
                    >
                      {chatbot.name}
                    </span>
                    {chatbot.uniqueForTeam ? (
                      <TbSquareLetterT size={20} title="Team chatbot" />
                    ) : chatbot.isGlobal ? (
                        <TbSquareLetterG size={20} title="Global chatbot" />
                      ) : (
                          <TbSquareLetterC size={20} title="Company-wide chatbot" />
                        )}
                  </div>
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={`Librarian-toolbar-buttons-navigation${isOnMobile ? "-mobile" : ""}`}>
        {!isOnlyLibrarian && !onlyLibrarian && (
          <Button variant="primary" onClick={() => navigate(isOnMobile ? "/navigate" : isMember && directToMemberPlan ? `/member/${memberId}` : '/')}>
            <FiHome title={"Home"} />
          </Button>
        )}
        <Button variant="primary" onClick={() => setShowSettings(true)}>
          <FiSettings title={"Settings"}/>
        </Button>
      </div>
      <div className={`Librarian-toolbar-buttons${isOnMobile ? "-mobile" : ""} ${isReferencesOpen ? 'open' : ''}`}>
        {!isOnMobile && (<Button
          variant="primary"
          onClick={() => {
            window.open(
              "https://arnvind.com/librarian-help/?pass=e8ac673f3af62a7fa4f2ef64bb1482d38dc5353d8a73e9ab7993a788ce03894c"
            )
          }}
          title="Help"
        >
          ?
        </Button>
        )}
        <Button
          variant="primary"
          onClick={() => {
            if (isInPlanningMode && isCurrentPlanner) {
              dispatch(teamStatus.showLogoutConfirm(true))
            } else {
              dispatch(auth.logout()).then(() => navigate("/login"))
            }
          }}
          title={"Logout"}
        >
          <FiLogOut />
        </Button>
      </div>
    </div>
  )
}
