import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"

import "./index.scss"

import Button from "react-bootstrap/Button"
// import { UpdateIndicator } from "components"
import { FiArrowLeft, FiHome, FiLogOut, FiCopy } from "react-icons/fi"
import { BsFillChatDotsFill } from "react-icons/bs"
import { FaKey } from "react-icons/fa"

import { auth, message, repositories, token, visual, librarian } from "state_management"
import { Popup } from "components"
import { Title, Container, Text } from "components/Form"

// Buttons used on various schedule screens.
export default function ScheduleButtonsAdmin() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const role = useSelector((state) => state.auth.role)
  const isCustomerAdmin = role === "customer_admin"
  const hasApiToken = useSelector((state) => state.auth.hasApiToken)
  const isFrontPage = isCustomerAdmin ? location.pathname === "/customers" : location.pathname === "/locations"
  const [activePopupApi, setactivePopupApi] = useState(false)
  const [showPrompt, setShowPrompt] = useState(false)
  const [showToken, setShowToken] = useState(false)
  const tokenString = useSelector((state) => state.token.token)
  const [currentTab, setCurrentTab] = useState(1)

  const config = useSelector((state) => state.librarian.config)

  const [generalPrefix, setGeneralPrefix] = useState(null)
  const [relevantDocumentation, setRelevantDocumentation] = useState(null)
  const [conversationPrompt, setConversationPrompt] = useState(null)
  const [currentQuestion, setCurrentQuestion] = useState(null)
  const [descriptionPrompt, setDescriptionPrompt] = useState(null)
  const [filePathPrompt, setFilePathPrompt] = useState(null)
  const [topDescriptionPrompt, setTopDescriptionPrompt] = useState(null)

  useEffect(() => {
    if (isCustomerAdmin) {
      dispatch(librarian.fetchConfig())
    }
  }, [isCustomerAdmin, dispatch])

  useEffect(() => {
    if (config) {
      setGeneralPrefix(config.generalPrefix)
      setRelevantDocumentation(config.relevantDocumentation)
      setConversationPrompt(config.conversationPrompt)
      setCurrentQuestion(config.currentQuestion)
      setDescriptionPrompt(config.descriptionPrompt)
      setFilePathPrompt(config.filePathPrompt)
      setTopDescriptionPrompt(config.topDescriptionPrompt)
    }
  }, [config])

  const handleGoBack = () => {
    updateRepository()
    navigate(-1)
  }

  const handleCopy = () => {
    navigator.clipboard
      .writeText(tokenString)
      .then(() => {
        dispatch(message.success("Copied to clipboard"))
      })
      .catch(() => {
        dispatch(message.error("Could not copy to clipboard"))
      })
  }

  const handleSubmit = () => {
    if (generalPrefix !== config.generalPrefix) {
      dispatch(librarian.setGeneralPrefix({ prefix: generalPrefix }))
    }

    if (relevantDocumentation !== config.relevantDocumentation) {
      dispatch(librarian.setRelevantDocumentation({ string: relevantDocumentation }))
    }

    if (conversationPrompt !== config.conversationPrompt) {
      dispatch(librarian.setConversationPrompt({ string: conversationPrompt }))
    }

    if (currentQuestion !== config.currentQuestion) {
      dispatch(librarian.setCurrentQuestion({ string: currentQuestion }))
    }

    if (descriptionPrompt !== config.descriptionPrompt) {
      dispatch(librarian.setDescriptionPrompt({ string: descriptionPrompt }))
    }

    if (filePathPrompt !== config.filePathPrompt) {
      dispatch(librarian.setFilePathPrompt({ string: filePathPrompt }))
    }

    if (topDescriptionPrompt !== config.topDescriptionPrompt) {
      dispatch(librarian.setTopDescriptionPrompt({ string: topDescriptionPrompt }))
    }

    setShowPrompt(false)
  }

  const updateRepository = () => {
    const isRepositoryView =
      location.pathname.includes("/customers/global-repositories/") ||
      location.pathname.includes("/locations/repository/")
    if (isRepositoryView) {
      const repositoryId = location.pathname.split("/")[3]
      dispatch(repositories.fetchRepository(repositoryId))
    }
  }

  return (
    <>
      {showPrompt && isCustomerAdmin && currentTab === 1 && (
        <Popup
          onCancel={() => {
            setShowPrompt(false)
          }}
          title="Chatbot System Prompt"
        >
          <div className="tab-buttons">
            <Button
              variant={currentTab === 1 ? "primary" : "outline-primary"}
              onClick={() => setCurrentTab(1)}
              size="sm"
            >
              1
            </Button>
            <Button
              variant={currentTab === 2 ? "primary" : "outline-primary"}
              onClick={() => setCurrentTab(2)}
              size="sm"
              style={{ marginLeft: "0.5em" }}
            >
              2
            </Button>
          </div>
          <Container>
            <Title>General prefix</Title>
            <Text
              value={generalPrefix}
              large={true}
              required
              onChange={(value) => {
                setGeneralPrefix(value)
              }}
              rows={5}
            />
          </Container>

          <span style={{ display: 'block', marginBottom: '1em' }}>
            Chatbot prefix
          </span>

          <Container>
            <Title>Relevant documentation</Title>
            <Text
              value={relevantDocumentation}
              large={true}
              required
              onChange={(value) => {
                setRelevantDocumentation(value)
              }}
              rows={5}
            />
          </Container>

          <span style={{ display: 'block', marginBottom: '1em' }}>
            Repository prefix
          </span>

          <Container>
            <Title>Previous conversation</Title>
            <Text
              value={conversationPrompt}
              large={true}
              required
              onChange={(value) => {
                setConversationPrompt(value)
              }}
              rows={5}
            />
          </Container>
          <Container>
            <Title>Current question</Title>
            <Text
              value={currentQuestion}
              large={true}
              required
              onChange={(value) => {
                setCurrentQuestion(value)
              }}
              rows={5}
            />
          </Container>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button
            style={{ marginLeft: "0.75em" }}
            variant="danger"
            onClick={() => {
              setGeneralPrefix(config.generalPrefix)
              setRelevantDocumentation(config.relevantDocumentation)
              setConversationPrompt(config.conversationPrompt)
              setCurrentQuestion(config.currentQuestion)
              setShowPrompt(false)
            }}
          >
            Cancel
          </Button>
        </Popup>
      )}
      {activePopupApi && isCustomerAdmin && (
        <Popup
          onCancel={() => {
            if (!showToken) {
              setactivePopupApi(false)
            }
          }}
          title="Customer Admin API key for Global Repositories"
        >
          {hasApiToken ? (
            <Button
              className="token-button"
              variant="danger"
              onClick={() => {
                dispatch(token.deleteCustomerAdminToken())
                dispatch(auth.setHasAPIToken(false))
              }}
            >
              Delete API key
            </Button>
          ) : (
            <Button
              className="token-button"
              variant="success"
              onClick={() => {
                dispatch(token.createCustomerAdminToken())
                dispatch(auth.setHasAPIToken(true))
                setShowToken(true)
              }}
            >
              Create API key
            </Button>
          )}
        </Popup>
      )}
      {showPrompt && isCustomerAdmin && currentTab === 2 && (
        <Popup
          onCancel={() => {
            setShowPrompt(false)
          }}
          title="Chatbot System Prompt"
        >
          <div className="tab-buttons">
            <Button
              variant={currentTab === 1 ? "primary" : "outline-primary"}
              onClick={() => setCurrentTab(1)}
              size="sm"
            >
              1
            </Button>
            <Button
              variant={currentTab === 2 ? "primary" : "outline-primary"}
              onClick={() => setCurrentTab(2)}
              size="sm"
              style={{ marginLeft: "0.5em" }}
            >
              2
            </Button>
          </div>
          <Container>
            <Title>Description Prompt</Title>
            <Text
              value={descriptionPrompt}
              large={true}
              required
              onChange={(value) => {
                setDescriptionPrompt(value)
              }}
              rows={5}
            />
          </Container>
          <Container>
            <Title>File Path Prompt</Title>
            <Text
              value={filePathPrompt}
              large={true}
              required
              onChange={(value) => {
                setFilePathPrompt(value)
              }}
              rows={5}
            />
          </Container>
          <Container>
            <Title>Top description prompt</Title>
            <Text
              value={topDescriptionPrompt}
              large={true}
              required
              onChange={(value) => {
                setTopDescriptionPrompt(value)
              }}
              rows={5}
            />
          </Container>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button
            style={{ marginLeft: "0.75em" }}
            variant="danger"
            onClick={() => {
              setDescriptionPrompt(config.descriptionPrompt)
              setFilePathPrompt(config.filePathPrompt)
              setTopDescriptionPrompt(config.topDescriptionPrompt)
              setShowPrompt(false)
            }}
          >
            Cancel
          </Button>
        </Popup>
      )}

      {/* Main Buttons Container */}
      <div className="ScheduleButtons admin">
        {/* Left Menu Buttons */}
        <div className="leftmenu-button" style={{ visibility: !isFrontPage ? "visible" : "hidden" }}>
          <Button variant="primary" title={"Go back"} onClick={handleGoBack}>
            <FiArrowLeft />
          </Button>
        </div>

        {/* Center Buttons */}
        <div className="center-buttons">
          <Button
            variant="primary"
            title={"Home"}
            onClick={() => {
              updateRepository()
              navigate(`/${isCustomerAdmin ? "customers" : "locations"}`)
            }}
          >
            <FiHome />
          </Button>
          {isCustomerAdmin && isFrontPage && (
            <div className="globalmessage-button">
              <Button
                variant="primary"
                title="Edit Global Message"
                onClick={() => {
                  dispatch(visual.showGlobalMessageConfig(true))
                }}
              >
                <BsFillChatDotsFill />
              </Button>
            </div>
          )}
          {isCustomerAdmin && (
            <Button
              variant="primary"
              title="API Key"
              onClick={() => {
                setactivePopupApi(true)
              }}
            >
              <FaKey />
            </Button>
          )}
          {isCustomerAdmin && isFrontPage && (
            <div className="globalmessage-button">
              <Button
                variant="primary"
                title="Edit System Prompts"
                onClick={() => {
                  setShowPrompt(true)
                }}
              >
                Chatbot System Prompts
              </Button>
            </div>
          )}
        </div>

        {/* Right Menu Buttons */}
        <div className="rightmenu-button">
          <Button
            variant="primary"
            onClick={() => {
              window.open("https://arnvind.com/plannertech-admin-help/?pass=SGVqc2FDZWxpbmU=")
            }}
            title="Help"
          >
            ?
          </Button>
          <Button
            variant="primary"
            onClick={() => dispatch(auth.logout()).then(() => navigate("/login"))}
            title={"Logout"}
          >
            <FiLogOut />
          </Button>
        </div>
      </div>

      {/* Token Popup */}
      {showToken && (
        <Popup
          nonScrollable
          className="tokenPopupContainer"
          onCancel={() => {
            dispatch(token.hideToken()) // Delete the token from redux
            setShowToken(false)
            setactivePopupApi(false)
          }}
        >
          <div>
            <h4>Permanent API token</h4>
            <p>
              This is a permanent API token, which allows you to access your team via our API. Copy and store it in a
              safe place.
            </p>
            <Container>
              <Title>API token</Title>
              <Title>
                {tokenString}{" "}
                <span title="copy to clipboard" className="teamconfig-copy-clipboard">
                  <FiCopy onClick={handleCopy} />
                </span>
              </Title>
            </Container>
          </div>
          <Button
            className="close-popup-button"
            variant="primary"
            onClick={() => {
              dispatch(token.hideToken()) // Delete the token from redux
              setShowToken(false)
            }}
          >
            Ok
          </Button>
        </Popup>
      )}
    </>
  )
}

