import React, { useState, useEffect, useRef } from "react"
import ReactMarkdown from "react-markdown"
import { useSelector, useDispatch } from "react-redux"

import { Toolbar } from "./Toolbar"
import { AiOutlinePlus } from "react-icons/ai"
import { FaMicrophone } from "react-icons/fa"
import { FaStop, FaArrowUp, FaArrowLeftLong } from "react-icons/fa6";
import { FiSettings } from "react-icons/fi"
import { TbPin, TbPinFilled } from "react-icons/tb";
import { ConfigContainer, Popup } from "components"
import { Container, Text, Title } from "components/Form"
import ReferencesPanel from "./ReferencesPanel"

import { Loading } from "components"
import { librarian, message } from "state_management"

import "./index.scss"
import logo from "utils/stylesheets/logo/logo.png"
import isOnMobile from "utils/scripts/isOnMobile"
import { Button} from "react-bootstrap"
import { analyzeAudioBlob } from "utils/scripts/analyseAudio"

export default function LibrarianScreen() {
  const dispatch = useDispatch()

  const [input, setInput] = useState("")
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [insertionLength, setInsertionLength] = useState(0);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [textInserted, setTextInserted] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [showConversationSettings, setShowConversationSettings] = useState(false);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [isReferencesOpen, setIsReferencesOpen] = useState(false);
  const [links, setLinks] = useState([]);

  const messagesEndRef = useRef(null)
  const textareaRef = useRef(null);

  const userId = useSelector((state) => state.auth.memberId)
  const initials = useSelector((state) => state.members[userId].initials)
  const activeConversationId = useSelector((state) => state.librarian.activeConversation)
  const conversation = useSelector((state) => state.librarian.conversation)
  const conversations = useSelector((state) => state.librarian.conversations)
  const chatbotId = useSelector((state) => state.librarian.selectedChatbot?.id)
  const isLoading = useSelector((state) => state.librarian.isLoading)
  const isFetching = useSelector((state) => state.librarian.isFetching)
  const companyName = useSelector((state) => state.auth.companyName)
  const chatbots = useSelector((state) => state.librarian.chatbots)
  const defaultChatbot = useSelector((state) => state.auth.defaultChatbot)
  const selectedChatbot = useSelector((state) => state.librarian.selectedChatbot)

  const toggleLibrarianHistory = () => setIsHistoryVisible(!isHistoryVisible);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false)
      dispatch(librarian.fetchRepositories())
      dispatch(librarian.fetchConversations())
      dispatch(librarian.fetchChatbots()).then((res)=> {
        const exists = res.some((chatbot) => chatbot.id === defaultChatbot)
        if (defaultChatbot && exists) {
          dispatch(librarian.setChatbot(res.find((chatbot) => chatbot.id === defaultChatbot)))
        }
      })
    }
  }, [dispatch, isFirstLoad, chatbots, defaultChatbot])


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [conversation])

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight(textareaRef.current);
    }
  }, [input]);

  useEffect(() => {
    if (insertionLength > 0 && textareaRef.current && textInserted) {
      textareaRef.current.focus();
      const startPos = cursorPosition - insertionLength;
      const endPos = cursorPosition;

      textareaRef.current.setSelectionRange(startPos, endPos);
      setTextInserted(false);
    }
  }, [insertionLength, cursorPosition, textInserted])

  if (isLoading) {
    return <Loading />
  }
  const ChatMessage = ({ messageInfo, isLoading = false }) => (
    <div className={`chat-message-container ${isLoading || messageInfo.is_bot || messageInfo.isBot ? "bot-message" : "user-message"}`}>
      {isLoading || messageInfo.is_bot || messageInfo.isBot ? (
        <div className="chat-message-icon">
          <img src={logo} alt="librarian logo" className="librarian-logo" />
        </div>
      ) : (
        <div className="chat-message-icon">
          {initials.slice(0, 2)}
        </div>
      )}
      {!isLoading ? (
        <div className="chat-message">
          {messageInfo.message.split(/\r?\n|\r|\n/g).map((line, index) => (
            <ReactMarkdown key={index}>{line}</ReactMarkdown>
          ))}
          {messageInfo.links?.length > 0 ? (
            <Button
              variant="primary"
              className="references-button"
              onClick={() => {
                setLinks(messageInfo.links)
                setIsReferencesOpen(true)
              }}>
              Open References
            </Button>
          ) : null}
        </div>) : <div className="chat-message thinking-dots" />}
    </div>
  )

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!chatbotId) {
      return dispatch(message.warning(`Please select a chatbot first`))
    }
    if (!isFetching) {
      const question = input.trim()
      if (!question) {
        return
      }
      setInput("")
      if (event.type === "submit") {
        event.target[1].style.height = "auto"
      } else {
        event.target.style.height = "auto"
      }

      dispatch(librarian.addToConversation({ message: question, isBot: false }))
      if (activeConversationId === "") {
        dispatch(librarian.getAnswer({ message: question, isBot: false, }, chatbotId))
      } else {
        dispatch(librarian.getAnswer({ message: question, isBot: false,  }, chatbotId, activeConversationId))
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event)
    }
  }

  function adjustTextareaHeight(target) {
    const textarea = target;

    textarea.style.overflowY = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    textarea.style.overflowY = 'auto';
  }

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      let audioChunks = [];

      recorder.addEventListener('dataavailable', event => {
        audioChunks.push(event.data);
      });

      recorder.addEventListener('stop', () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        // Send audioBlob to backend here
        analyzeAudioBlob(audioBlob).then(isSpeechDetected => {
          if (isSpeechDetected) {
            dispatch(librarian.getSpeechToTextQuestion(audioBlob)).then((res) => {
              setInput(prevInput => {
                  const newText = res.text.trim();
                  const beforeCursor = prevInput.substring(0, cursorPosition);
                  const afterCursor = prevInput.substring(cursorPosition);

                  const updatedInput = `${beforeCursor}${newText}${afterCursor}`;

                  const newCursorPosition = beforeCursor.length + newText.length;

                  setCursorPosition(newCursorPosition);
                  setInsertionLength(newText.length);
                  setTextInserted(true);

                  return updatedInput;
              });
          });
          } else {
            return dispatch(message.warning(`No sound detected. Please try again.`))
          }
        });
        audioChunks = [];
      });

      recorder.start(500);
      setIsRecording(true);
    } catch (error) {
      return dispatch(message.warning(`No microphone detected. Please try again.`))
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
    }
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <div className="LibrarianScreen-main-container">
      {showConversationSettings && (
        <Popup nonScrollable onCancel={() => setShowConversationSettings(false)}>
          <ConfigContainer
            conversation={currentConversation}
            cancelHandler={() => setShowConversationSettings(false)}
            submitHandler={() => {
              dispatch(librarian.editConversation(currentConversation.id, { name: currentConversation.name }))
              setShowConversationSettings(false)
            }}
            deleteHandler={() => {
              dispatch(librarian.deleteConversation(currentConversation.id))
              setShowConversationSettings(false)
            }}
            title="Edit Conversation"
          >
            <Container>
              <Title>Name</Title>
              <Text
                value={currentConversation.name}
                onChange={(value) => setCurrentConversation({ ...currentConversation, name: value })}
              />
            </Container>
          </ConfigContainer>

        </Popup>
      )}
      <div className={`Librarian-history ${isHistoryVisible && isOnMobile ? 'visible' : ''}`}>
        <div className="button-container">
          {isOnMobile && (
            <Button variant="primary" className="go-back-to-chat" onClick={toggleLibrarianHistory}>
              <FaArrowLeftLong title={"Go back to chat"} />
            </Button>
          )}
          <Button className="Librarian-new-chat-button" onClick={() => {
            setIsHistoryVisible(false)
            setIsReferencesOpen(false)
            dispatch(librarian.refreshChat())
          }}>
            <AiOutlinePlus />
            New chat
          </Button>
        </div>
        <div className="Librarian-history-conversations">
          {conversations.length > 0 && conversations.map((conversation) => (
            <div
              key={conversation.id}
              className={`conversation ${conversation.id === activeConversationId ? 'active' : ''}`}
              onClick={() => {
                dispatch(librarian.setConversation(conversation.id))
                const chatbot = chatbots.find(chatbot => chatbot.id === conversation.chatbotId)
                dispatch(librarian.setChatbot(chatbot))
                setIsReferencesOpen(false)
              }}
            >
              <div className="conversation-info">
                <p>{conversation.name}</p>
              </div>
              <div className="conversation-icon">
                {conversation.isPinned ? (
                  <TbPinFilled
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(librarian.editConversation(conversation.id, { isPinned: false}))
                    }}
                    title="Unpin conversation"
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                    <TbPin
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(librarian.editConversation(conversation.id, { isPinned: true}))
                      }}
                      title="Pin conversation"
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                <FiSettings
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentConversation(conversation);
                    setShowConversationSettings(true);
                  }}
                  title="Edit conversation"
                />
              </div>
            </div>
          ))}
        </div>

      </div>
      <div className="chat-window">
        <div>
          <Toolbar isReferencesOpen={isReferencesOpen} toggleLibrarianHistory={toggleLibrarianHistory}/>
          <ReferencesPanel links={links} isOpen={isReferencesOpen} onClose={() => setIsReferencesOpen(false)} />
        </div>
        <span className={`Librarian-selected-chatbot${isOnMobile ? "-mobile" : ""}`}>{selectedChatbot?.name}</span>
        <div className={`chat-messages${isOnMobile ? "-mobile" : ""}`}>
          {conversation.length === 0 && (
            <div className="chat-messages-empty-board">
              <h1>{companyName}<br/>QC LIBRARIAN 3.0</h1>
            </div>
          )}
          {conversation.length > 0 && conversation.map((messageInfo, index) => (
            <ChatMessage key={index} messageInfo={messageInfo} />
          ))}
          {isFetching && <ChatMessage isLoading={true} />}
          <div ref={messagesEndRef} />
        </div>
        <div className="chat-config">
          <form onSubmit={handleSubmit} className="chat-input">
            <button type="button" className="microphone-button" onClick={toggleRecording}>
              {isRecording ? <FaStop /> : <FaMicrophone />}
            </button>
            <textarea
              value={input}
              onChange={(e) => {
                setInput(e.target.value)
                }
              }
              onKeyDown={handleKeyDown}
              onInput={(e) => {
                setCursorPosition(e.target.selectionStart);
              }}
              onClick={(e) => {
                setCursorPosition(e.target.selectionStart);
              }}
              rows={getRows(input)}
              placeholder="Type your message..."
              ref={textareaRef}
            />
            <button
              className="send-button"
              type="submit"
            >
              <FaArrowUp/>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

// used to determine the number of rows in the textarea. Currently has maximum of 5 rows
const getRows = (input) => {
  const lines = input.split(/\r?\n|\r|\n/g)
  return lines.length > 5 ? 5 : lines.length
}
